import React from "react";
import { colors } from "../styles/theme.tsx";
import Grid from "@mui/material/Grid2";
import { Box, Typography } from "@mui/material";
import { Link } from "react-router-dom";

export default function Footer({ setModuleText }) {
  return (
    <footer style={{ backgroundColor: colors.white, padding: "60px 50px" }}>
      {/* SECCION FINAL */}
      <Grid
        container
        spacing={4}
        columns={{ lg: 15 }}
        width={"100%"}
        height={"100%"}
      >
        <Grid size={{ xs: 12, md: 6, lg: 3 }}>
          <Box sx={{ width: 121, height: "auto" }}>
            <Link to="/" style={{ textDecoration: "none", color: "inherit" }}>
              <img
                src={require("../assets/image/LogoHorizontalVerde.webp")}
                alt={"Logo"}
                loading="lazy"
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "contain",
                }}
              />
            </Link>
          </Box>
          <div
            style={{
              width: 45,
              height: 2,
              background: colors.primary,
            }}
          />
          <Grid display={"flex"} flexDirection={"row"} mt={"30px"}>
            <Grid size={6} display={"flex"} alignItems={"center"}>
              <Box sx={{ width: 40, height: 40 }}>
                <img
                  src={require("../assets/image/headphones.webp")}
                  alt={"Logo"}
                  loading="lazy"
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "contain",
                  }}
                />
              </Box>
            </Grid>
            <Grid size={6} sx={{ marginLeft: "15px" }}>
              <Typography
                sx={{
                  fontSize: { xs: 16, md: 18 },
                  color: colors.gray,
                  lineHeight: 1,
                }}
              >
                Comunícate con nuestra
              </Typography>
              <Typography
                sx={{
                  fontSize: { xs: 16, md: 18 },
                  color: colors.gray,
                  lineHeight: 1,
                }}
              >
                Línea de atención
              </Typography>
              <Typography
                sx={{
                  fontSize: { xs: 16, md: 18 },
                  fontWeight: "bold",
                  color: colors.primary,
                  lineHeight: 1,
                  marginTop: "6px",
                }}
              >
                (317) 420 78451
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid size={{ xs: 12, md: 6, lg: 3 }} mt={{ xs: 4, md: 0 }}>
          <Typography
            sx={{
              fontWeight: "bold",
              fontSize: { xs: 19, md: 25 },
              color: colors.gray,
            }}
          >
            Sobre Nosotros
          </Typography>
          <div
            style={{
              width: 59,
              height: 2,
              background: colors.primary,
              marginTop: "10px",
            }}
          />
          <Typography
            sx={{
              fontSize: { xs: 16, md: 18 },
              color: colors.gray,
              lineHeight: 1,
              my: "18px",
            }}
          >
            <Link
              to="/quienes-somos"
              style={{ textDecoration: "none", color: "inherit" }}
            >
              Quienes somos
            </Link>
          </Typography>
          <Typography
            sx={{
              fontSize: { xs: 16, md: 18 },
              color: colors.gray,
              lineHeight: 1,
              my: "18px",
            }}
          >
            <Link
              to="/cultura-organizacional"
              style={{ textDecoration: "none", color: "inherit" }}
            >
              Cultura organizacional
            </Link>
          </Typography>
          <Typography
            sx={{
              fontSize: { xs: 16, md: 18 },
              color: colors.gray,
              lineHeight: 1,
              my: "18px",
            }}
          >
            <Link
              to="/trabaja-con-nosotros"
              style={{ textDecoration: "none", color: "inherit" }}
            >
              Trabaja con nosotros
            </Link>
          </Typography>
          <Typography
            sx={{
              fontSize: { xs: 16, md: 18 },
              color: colors.gray,
              lineHeight: 1,
              my: "18px",
            }}
          >
            Protección de datos personales
          </Typography>
        </Grid>
        <Grid size={{ xs: 12, md: 4, lg: 3 }}>
          <Typography
            sx={{
              fontWeight: "bold",
              fontSize: { xs: 19, md: 25 },
              color: colors.gray,
            }}
          >
            Interés
          </Typography>
          <div
            style={{
              width: 59,
              height: 2,
              background: colors.primary,
              marginTop: "10px",
            }}
          />
          <Typography
            sx={{
              fontSize: { xs: 16, md: 18 },
              color: colors.gray,
              lineHeight: 1,
              my: "18px",
            }}
          >
            Noticias
          </Typography>
        </Grid>
        <Grid size={{ xs: 12, md: 4, lg: 3 }}>
          <Typography
            sx={{
              fontWeight: "bold",
              fontSize: { xs: 19, md: 25 },
              color: colors.gray,
            }}
          >
            Centro de ayuda
          </Typography>
          <div
            style={{
              width: 59,
              height: 2,
              background: colors.primary,
              marginTop: "10px",
            }}
          />
          <Typography
            sx={{
              fontSize: { xs: 16, md: 18 },
              color: colors.gray,
              lineHeight: 1,
              my: "18px",
            }}
          >
            <Link
              to="/centro-de-ayuda"
              style={{ textDecoration: "none", color: "inherit" }}
            >
              Servicio al cliente
            </Link>
          </Typography>
          <Typography
            sx={{
              fontSize: { xs: 16, md: 18 },
              color: colors.gray,
              lineHeight: 1,
              my: "18px",
            }}
          >
            PQR´S
          </Typography>
          <Typography
            sx={{
              fontSize: { xs: 16, md: 18 },
              color: colors.gray,
              lineHeight: 1,
              my: "18px",
            }}
          >
            <Link
              to="/centro-de-ayuda"
              style={{ textDecoration: "none", color: "inherit" }}
            >
              Contacto
            </Link>
          </Typography>
        </Grid>
        <Grid size={{ xs: 12, md: 4, lg: 3 }}>
          <Typography
            sx={{
              fontWeight: "bold",
              fontSize: { xs: 19, md: 25 },
              color: colors.gray,
            }}
          >
            Ingreso
          </Typography>
          <div
            style={{
              width: 59,
              height: 2,
              background: colors.primary,
              marginTop: "10px",
            }}
          />
          <Typography
            sx={{
              fontSize: { xs: 16, md: 18 },
              color: colors.gray,
              lineHeight: 1,
              my: "18px",
            }}
          >
            <Link
              to="/iniciar-sesion"
              style={{ textDecoration: "none", color: "inherit" }}
              onClick={() => setModuleText("1")}
            >
              Inicia sesión como empresario
            </Link>
          </Typography>
          <Typography
            sx={{
              fontSize: { xs: 16, md: 18 },
              color: colors.gray,
              lineHeight: 1,
              my: "18px",
            }}
          >
            <Link
              to="/iniciar-sesion"
              style={{ textDecoration: "none", color: "inherit" }}
              onClick={() => setModuleText("2")}
            >
              Inicia sesión como microempresario
            </Link>
          </Typography>
        </Grid>
      </Grid>
    </footer>
  );
}
