import React from "react";
import { Box, Typography, Button, ButtonProps, TextField } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { styled } from "@mui/material/styles";
import { colors, sizes } from "../../styles/theme.tsx";
import InputAdornment from "@mui/material/InputAdornment";
import PersonIcon from "@mui/icons-material/Person";
import LockIcon from "@mui/icons-material/Lock";
import { interpretModuleText } from "../../functions/commonFunctions.tsx";

export default function Login({ moduleText }) {
  const ColorButton = styled(Button)<ButtonProps>(({ theme }) => ({
    color: colors.white,
    backgroundColor: colors.primary,
    "&:hover": {
      backgroundColor: colors.white,
      border: `1px solid ${colors.primary}`,
      color: colors.primary,
    },
    borderRadius: 19,
    width: 245,
    fontWeight: 700,
    margin: "0 auto",
    marginTop: 20,
  }));

  const CssTextField = styled(TextField)({
    "& label": {
      paddingLeft: "15px",
      transition: "padding-left 0.4s ease-in-out",
    },
    "& label.Mui-focused": {
      color: colors.primary,
      paddingLeft: "0px",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#B2BAC2",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#E0E3E7",
        borderRadius: 10,
      },
      "&:hover fieldset": {
        borderColor: "#B2BAC2",
        borderRadius: 10,
      },
      "&.Mui-focused fieldset": {
        borderColor: colors.primary,
        borderRadius: 10,
      },
    },
    background: colors.grayLight,
    width: "70%",
    marginBottom: "12px",
    borderRadius: 10,
  });

  return (
    <Box
      sx={{
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        overflowX: "hidden",
      }}
    >
      <Box
        sx={{
          flex: "1",
          flexGrow: 1,
          marginTop: "50px !important",
          paddingTop: "60px",
          width: { xs: "95%", md: "70%", lg: "60%", xl: "40%" },
          display: "flex",
          alignItems: "center",
          margin: "0 auto",
        }}
      >
        <Box
          display={"flex"}
          flexDirection={"column"}
          sx={{
            flexGrow: 1,
            margin: "0 auto",
          }}
        >
          <Box sx={{ position: "relative", width: "100%" }}>
            <div
              style={{
                width: 10,
                height: 89,
                background: colors.primary,
                position: "absolute",
                top: "45%",
                left: 0,
                borderRadius: 2,
                transform: "translateY(-50%)",
              }}
            />
            <Box pl="50px">
              <Typography
                sx={{
                  fontWeight: "bold",
                  fontSize: { xs: 32, md: 43 },
                  color: colors.primary,
                  textTransform: "uppercase",
                }}
                lineHeight={1}
              >
                INICIAR SESIÓN
              </Typography>

              <Typography
                sx={{
                  fontSize: { xs: 21.5, md: 24.5 },
                  color: colors.gray,
                  marginTop: "10px",
                  lineHeight: 1.3,
                }}
              >
                Módulo {interpretModuleText(moduleText)}
              </Typography>
            </Box>
          </Box>
          <Box>
            <Grid
              sx={{
                background: colors.white,
                height: "auto",
                borderRadius: "18px",
                boxShadow: "0px 7px 41.5px -11px #00000040",
                padding: {
                  xs: "40px 20px 100px 20px",
                  sm: " 70px",
                },
                position: "relative",
                display: "flex",
                flexDirection: "column",
                width: "100%",
                mb: 10,
              }}
              mt={10}
            >
              <Box
                sx={{
                  width: { xs: 180, lg: 235 },
                  height: { xs: 180, lg: 235 },
                  margin: "0 auto",
                  marginTop: { xs: "-80px", sm: "-120px" },
                }}
              >
                <img
                  src={require("../../assets/image/user.webp")}
                  alt={"Logo"}
                  loading="lazy"
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "contain",
                  }}
                />
              </Box>

              <Box
                display={"flex"}
                flexDirection={"column"}
                alignItems={"center"}
                mt={6}
              >
                <CssTextField
                  placeholder={
                    moduleText === "1" ? "Nit empresa" : "Número de cédula"
                  }
                  id="custom-css-outlined-input"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <PersonIcon />
                      </InputAdornment>
                    ),
                  }}
                  variant="outlined"
                  sx={{ width: { xs: "100%", sm: "70%" } }}
                />
                <CssTextField
                  placeholder="Contraseña"
                  id="custom-css-outlined-input"
                  type="password"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <LockIcon />
                      </InputAdornment>
                    ),
                  }}
                  variant="outlined"
                  sx={{ width: { xs: "100%", sm: "70%" } }}
                />
                <ColorButton variant="contained" sx={{ fontSize: 20 }}>
                  INGRESAR
                </ColorButton>
              </Box>
            </Grid>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
