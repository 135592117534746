import React from "react";
import {
  Box,
  Button,
  ButtonProps,
  Checkbox,
  TextField,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import { styled } from "@mui/material/styles";
import { colors, sizes } from "../../styles/theme.tsx";

export default function ContactUs() {
  const CssTextField = styled(TextField)({
    "& label": {
      paddingLeft: "15px", // Aplica el padding-left cuando el label no está enfocado
      transition: "padding-left 0.4s ease-in-out", // Añade una transición suave
    },
    "& label.Mui-focused": {
      color: colors.primary,
      paddingLeft: "0px",
    },
    "& label.MuiInputLabel-shrink": {
      paddingLeft: "0px", // Elimina el padding cuando el label está shrinked (arriba)
    },

    "& .MuiInput-underline:after": {
      borderBottomColor: "#B2BAC2",
      paddingLeft: "0px",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#E0E3E7",
        borderRadius: 10,
      },
      "&:hover fieldset": {
        borderColor: "#B2BAC2",
        borderRadius: 10,
      },
      "&.Mui-focused fieldset": {
        borderColor: colors.primary,
        borderRadius: 10,
      },
      "& .MuiInputBase-inputMultiline": {
        resize: "vertical",
      },
    },
    background: colors.grayLight,
    width: "100%",
    borderRadius: 10,
    paddingLeft: "0px",
  });

  const ColorButton = styled(Button)<ButtonProps>(({ theme }) => ({
    color: colors.white,
    backgroundColor: colors.primary,
    "&:hover": {
      backgroundColor: colors.white,
      border: `1px solid ${colors.primary}`,
      color: colors.primary,
    },
    borderRadius: 19,
    width: 245,
    fontWeight: 700,
    margin: "0 auto",
  }));

  return (
    <Box
      sx={{
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        overflowX: "hidden",
      }}
    >
      <Box
        sx={{
          flex: "1",
          flexGrow: 1,
          marginTop: `${sizes.header}px`,
          paddingTop: "60px",
        }}
      >
        <Box
          display={"flex"}
          flexDirection={"column"}
          sx={{
            flexGrow: 1,
            width: { xs: "95%", lg: "60%", xl: "50%" },
            margin: "0 auto",
          }}
        >
          {/* Titulo */}
          <Box
            sx={{
              padding: "50px",
              position: "relative",
              display: "flex",
              width: { md: "90%" },
              margin: "0 auto",
            }}
          >
            <div
              style={{
                width: 10,
                height: 120,
                background: colors.primary,
                position: "absolute",
                top: "50%",
                left: 0,
                borderRadius: 2,
                transform: "translateY(-50%)",
              }}
            />

            <Box>
              <Typography
                sx={{
                  fontWeight: "bold",
                  fontSize: { xs: 32, md: 43 },
                  color: colors.primary,
                  textTransform: "uppercase",
                }}
                lineHeight={1}
              >
                COMUNÍCATE CON NOSOTROS
              </Typography>

              <Typography
                sx={{
                  fontSize: { xs: 16, md: 18 },
                  color: colors.gray,
                  marginTop: "10px",
                  lineHeight: 1.3,
                }}
              >
                Completa el formulario y nuestros asesores se pondrán en
                contacto contigo. Ellos te asesorarán sobre las mejores
                soluciones para ti o para tu empresa.
              </Typography>
            </Box>
          </Box>
          {/* Formulario */}
          <Box mt={5} width={"100%"}>
            <Box
              justifyContent={"center"}
              alignItems={"center"}
              display={"flex"}
              flexDirection={"column"}
              sx={{ flexGrow: 1 }}
            >
              <Box
                sx={{
                  background: colors.white,
                  height: "auto",
                  borderRadius: "18px",
                  boxShadow: "0px 7px 41.5px -11px #00000040",
                  padding: {
                    xs: "40px 20px 100px 20px",
                    sm: "40px 70px 100px 70px",
                  },
                  position: "relative",
                  display: "flex",
                  flexDirection: "column",
                  marginBottom: { xs: 10, md: 20 },
                }}
              >
                <div
                  style={{
                    width: 136,
                    height: 10,
                    background: colors.primary,
                    position: "absolute",
                    left: "50%",
                    top: 0,
                    transform: "translatex(-50%)",
                  }}
                />

                <Box
                  display={"flex"}
                  flexDirection={"column"}
                  alignItems={"center"}
                >
                  <Typography
                    sx={{
                      fontSize: { xs: 19, md: 21.8 },
                      color: colors.black,
                      textAlign: "center",
                      marginBottom: "37px",
                    }}
                  >
                    Escríbenos tu mensaje
                  </Typography>
                  <Grid container spacing={2.5} width={"100%"}>
                    <Grid size={{ xs: 12, md: 6 }}>
                      <CssTextField
                        label="Nombre completo"
                        id="custom-css-outlined-input"
                      />
                    </Grid>
                    <Grid size={{ xs: 12, md: 6 }}>
                      <CssTextField
                        label="Correo electrónico"
                        id="custom-css-outlined-input"
                        type="email"
                      />
                    </Grid>
                    <Grid size={{ xs: 12, md: 6 }}>
                      <CssTextField
                        label="Ciudad"
                        id="custom-css-outlined-input"
                      />
                    </Grid>
                    <Grid size={{ xs: 12, md: 6 }}>
                      <CssTextField
                        label="Nit de empresa (si aplica)"
                        id="custom-css-outlined-input"
                      />
                    </Grid>
                    <Grid size={12}>
                      <CssTextField
                        label="Número de contacto"
                        id="custom-css-outlined-input"
                      />
                    </Grid>
                    <Grid size={12}>
                      <CssTextField
                        label="Escribe tu mensaje"
                        id="custom-css-outlined-input"
                        multiline
                        maxRows={4}
                        minRows={4}
                      />
                    </Grid>
                  </Grid>
                </Box>
                <Box
                  display={"flex"}
                  flexDirection={"row"}
                  alignItems={"center"}
                  justifyContent={"center"}
                  mt={2.5}
                  mb={4}
                >
                  <Checkbox />
                  <Typography
                    sx={{
                      fontSize: 13,
                      color: colors.black,
                    }}
                  >
                    He leído y acepto la{" "}
                    <span style={{ color: colors.primary }}>
                      Política de Tratamiento de Datos
                    </span>
                  </Typography>
                </Box>
                <ColorButton variant="contained" sx={{ fontSize: 20 }}>
                  ENVIAR
                </ColorButton>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      {/* <Box sx={{ width: "100%" }}>
        <Footer />
      </Box> */}
    </Box>
  );
}
