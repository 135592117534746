import React from "react";
import { Box, Typography, Button, ButtonProps } from "@mui/material";
import Grid from "@mui/material/Grid2";

import { styled } from "@mui/material/styles";
import { colors, sizes } from "../../styles/theme.tsx";
import { useNavigate } from "react-router-dom";

export default function MarketingStrategies() {
  const navigate = useNavigate();

  const ColorButton = styled(Button)<ButtonProps>(({ theme }) => ({
    color: colors.white,
    backgroundColor: colors.primary,
    "&:hover": {
      backgroundColor: colors.white,
      border: `1px solid ${colors.primary}`,
      color: colors.primary,
    },
    borderRadius: 19,
    fontWeight: 700,
    marginTop: 45,
  }));

  return (
    <Box
      sx={{
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        overflowX: "hidden",
      }}
    >
      <Box
        sx={{
          flex: "1",
          flexGrow: 1,
          marginTop: `${sizes.header}px`,
          paddingTop: { xs: "20px", md: "60px" },
        }}
      >
        <Box
          sx={{
            padding: "50px ",
            position: "relative",
            display: "flex",
            width: { xs: "60%", lg: "50%", xl: "36%" },
            margin: "0 auto",
          }}
        >
          <div
            style={{
              width: 10,
              height: 120,
              background: colors.primary,
              position: "absolute",
              top: "50%",
              left: 0,
              borderRadius: 2,
              transform: "translateY(-50%)",
            }}
          />

          <Box>
            <Typography
              sx={{
                fontWeight: "bold",
                fontSize: { xs: 32, md: 43 },
                color: colors.primary,
                textTransform: "uppercase",
              }}
              lineHeight={1}
            >
              ESTRATEGIAS DE MARKETING
            </Typography>

            <Typography
              sx={{
                fontSize: { xs: 16, md: 18 },
                color: colors.gray,
                marginTop: "10px",
                lineHeight: 1.3,
              }}
            >
              ¿Quieres impulsar tus ventas? conoce medios digitales que te
              abrirán más canales de venta y genera más posicionamiento en las
              redes
            </Typography>
          </Box>
        </Box>
        {/* Info */}
        <Box mt={{ xs: 15, lg: 5 }}>
          <Box
            justifyContent={"center"}
            alignItems={"center"}
            display={"flex"}
            flexDirection={"column"}
            sx={{ flexGrow: 1 }}
          >
            <Grid
              container
              spacing={{ lg: 5 }}
              width={{ xs: "90%", xl: "80%" }}
              sx={{
                height: "auto",
                alignItems: "stretch",
              }}
            >
              <Grid
                size={{ lg: 5 }}
                sx={{
                  position: "relative",
                  alignItems: "center",
                  justifyContent: "center",
                  display: "flex",
                  width: "100%",
                  flexDirection: "column",
                }}
              >
                <Box
                  sx={{
                    width: {
                      xs:"340px",
                      sm: "450px",
                      md: "500px",
                      lg: "500px",
                      xl: "550px",
                    },
                    height: {
                      xs:"340px",
                      sm: "450px",
                      md: "500px",
                      lg: "500px",
                      xl: "550px",
                    },
                    marginTop: "-153px",
                  }}
                >
                  <img
                    src={require("../../assets/image/social_media.webp")}
                    alt={"Logo"}
                    loading="lazy"
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "contain",
                    }}
                  />
                </Box>
              </Grid>
              <Grid
                size={{ lg: 7 }}
                sx={{
                  padding: "0 14px 50px 0px",
                  position: "relative",
                  display: "flex",
                  flexDirection: "column",
                  marginBottom: { xs: 8, md: 15 },
                }}
              >
                <Box sx={{ flex: "1" }}>
                  <Typography
                    mt={{ xs: -1, lg: "20px" }}
                    sx={{
                      fontWeight: "bold",
                      fontSize: { xs: 20, md: 23 },
                      color: colors.gray,
                      lineHeight: 1.3,
                    }}
                  >
                    ¡Impulsa tus ventas con nuestras Estrategias de Marketing
                    Digital!
                    <br />
                    <br />
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: { xs: 20, md: 23 },
                      color: colors.gray,
                      marginTop: "6px",
                      lineHeight: 1.3,
                    }}
                  >
                    Si buscas llevar tu negocio al siguiente nivel y aumentar
                    tus ventas, nuestro servicio de Estrategias de Marketing
                    Digital es la opción ideal.
                    <br />
                    <br />
                    Nos especializamos en diseñar campañas personalizadas que
                    aprovechan al máximo los medios digitales para atraer más
                    clientes y generar mayor posicionamiento de tu marca en las
                    redes sociales.
                    <br />
                    <br />
                    Hoy en día, estar presente en el entorno digital no es
                    suficiente; es fundamental destacar y conectar con tu
                    audiencia de manera efectiva.
                    <br />
                    <br />
                    Por eso, te ofrecemos un enfoque integral que abarca desde
                    la planificación y ejecución de campañas publicitarias hasta
                    la gestión de contenido y el desarrollo de estrategias que
                    te permitirán abrir nuevos canales de venta.
                    <br />
                    <br />
                    Ya sea a través de redes sociales, email marketing,
                    publicidad en línea o SEO, te ayudamos a incrementar tu
                    visibilidad y atraer al público correcto.
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
        {/* Boton */}

        <Box
          sx={{
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
            width: { xs: "90%", sm: "80%" },
            margin: "0 auto",
          }}
        >
          <Typography
            sx={{
              fontWeight: "bold",
              fontSize: { xs: 32, md: 43 },
              color: colors.primary,
              textTransform: "uppercase",
              textAlign: "center",
            }}
            lineHeight={1}
          >
            ¿NECESITAS ESTE SERVICIO?
          </Typography>

          <Typography
            sx={{
              fontSize: { xs: 19, md: 21.8 },
              color: colors.black,
              marginTop: "10px",
              textAlign: "center",
            }}
            lineHeight={1}
          >
            Nuestros asesores se comunicarán contigo
          </Typography>
          <ColorButton
            onClick={() => navigate("/registrarse")}
            variant="contained"
            sx={{
              fontSize: { xs: 20, md: 26 },
              width: { xs: 250, sm: 350, md: 428 },
              marginBottom: { xs: 15, md: 28 },
            }}
          >
            REGÍSTRATE AQUÍ
          </ColorButton>
        </Box>
      </Box>
    </Box>
  );
}
