import React from "react";
import { Box, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { colors, sizes } from "../styles/theme.tsx";
import Slider from "react-slick";
import { Link } from "react-router-dom";

export default function Home() {
  var settings = {
    dots: true,
    infinite: true,
    arrows: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
    appendDots: (dots) => (
      <div
        style={{
          position: "absolute",
          bottom: 20,
        }}
      >
        <ul style={{ margin: "0px" }}> {dots} </ul>
      </div>
    ),
    autoplay: false,
    speed: 500,
    autoplaySpeed: 5000,
    cssEase: "linear",
    pauseOnHover: false,
  };

  return (
    <Box
      sx={{
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        overflowX: "hidden",
      }}
    >
      <Box
        sx={{
          flex: "1",
          flexGrow: 1,
          marginTop: `${sizes.header}px`,
        }}
      >
        <Box className="slider-container">
          <Slider
            {...settings}
          >
            <div>
              <img
                src={require("../assets/image/Banner1.webp")}
                alt={"Logo"}
                loading="lazy"
                style={{ width: "100%",maxHeight:"auto",  objectFit: "contain" }}
              />
            </div>
            <div>
              <img
                src={require("../assets/image/Banner1.webp")}
                alt={"Logo"}
                loading="lazy"
                style={{ width: "100%", objectFit: "contain" }}
              />
            </div>
          </Slider>
        </Box>
        {/* Cards */}
        <Box mt={{ xs: 5, md: 10 }}>
          <Box
            justifyContent={"center"}
            alignItems={"center"}
            display={"flex"}
            flexDirection={"column"}
            sx={{
              flexGrow: 1,
              width: { xs: "90%", lg: "80%" },
              margin: "0 auto",
            }}
          >
            <Typography
              sx={{
                fontWeight: "bold",
                fontSize: { xs: 32, md: 43 },
                color: colors.primary,
                textAlign: "center",
              }}
              lineHeight={1}
            >
              Conoce nuestros beneficios
            </Typography>
            <Typography
              sx={{
                fontWeight: "bold",
                fontSize: { xs: 19, md: 25 },
                color: colors.gray,
                textAlign: "center",
                mt: { xs: 1, sm: 0 },
              }}
              lineHeight={1}
            >
              Los mejores planes para tu empresa
            </Typography>
            <Grid
              container
              mt={"52px"}
              spacing={3}
              width={"100%"}
              sx={{ height: "auto", alignItems: "stretch" }}
            >
              <Grid
                size={{ md: 6, lg: 4 }}
                sx={{
                  background: colors.white,
                  minHeight: 336,
                  borderRadius: "18px",
                  boxShadow: "0px 7px 41.5px -11px #00000040",
                  padding: "50px 14px 50px 50px",
                  position: "relative",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <div
                  style={{
                    width: 10,
                    height: 136,
                    background: colors.primary,
                    position: "absolute",
                    top: "50%",
                    left: 0,
                    borderRadius: 2,
                    transform: "translateY(-50%)",
                  }}
                />
                <Box sx={{ width: 40, height: 40 }}>
                  <img
                    src={require("../assets/image/personmail.webp")}
                    alt={"Logo"}
                    loading="lazy"
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "contain",
                    }}
                  />
                </Box>
                <Box sx={{ flex: "1" }}>
                  <Typography
                    mt={"18px"}
                    sx={{
                      fontWeight: "bold",
                      fontSize: { xs: 19, md: 25 },
                      color: colors.gray,
                    }}
                  >
                    Domicilios locales
                  </Typography>

                  <Typography
                    sx={{
                      fontSize: { xs: 16, md: 18 },
                      color: colors.gray,
                      marginTop: "6px",
                    }}
                  >
                    Entrega tus productos de manera rápida y eficiente con
                    nosotros a través de domiciliarios
                  </Typography>
                </Box>

                <Typography
                  sx={{
                    fontSize: { xs: 16, md: 18 },
                    fontWeight: "bold",
                    color: colors.primary,
                    marginTop: "6px",
                  }}
                >
                  <Link
                    to="/domicilios-locales"
                    style={{ textDecoration: "none", color: "inherit" }}
                  >
                    {` Ver más >`}
                  </Link>
                </Typography>
              </Grid>
              <Grid
                size={{ md: 6, lg: 4 }}
                sx={{
                  background: colors.white,
                  minHeight: 336,
                  borderRadius: "18px",
                  boxShadow: "0px 7px 41.5px -11px #00000040",
                  padding: "50px 14px 50px 50px",
                  position: "relative",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <div
                  style={{
                    width: 10,
                    height: 136,
                    background: colors.primary,
                    position: "absolute",
                    top: "50%",
                    left: 0,
                    borderRadius: 2,
                    transform: "translateY(-50%)",
                  }}
                />
                <Box sx={{ width: 40, height: 40 }}>
                  <img
                    src={require("../assets/image/boxing.webp")}
                    alt={"Logo"}
                    loading="lazy"
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "contain",
                    }}
                  />
                </Box>
                <Box sx={{ flex: "1" }}>
                  <Typography
                    mt={"18px"}
                    sx={{
                      fontWeight: "bold",
                      fontSize: { xs: 19, md: 25 },
                      color: colors.gray,
                    }}
                  >
                    Entrega y transporte
                  </Typography>

                  <Typography
                    sx={{
                      fontSize: { xs: 16, md: 18 },
                      color: colors.gray,
                      marginTop: "6px",
                    }}
                  >
                    Para aquellos comerciantes que deseen transportar su
                    mercancía
                  </Typography>
                </Box>

                <Typography
                  sx={{
                    fontSize: { xs: 16, md: 18 },
                    fontWeight: "bold",
                    color: colors.primary,
                    marginTop: "6px",
                  }}
                >
                  <Link
                    to="/entrega-y-transporte"
                    style={{ textDecoration: "none", color: "inherit" }}
                  >
                    {` Ver más >`}
                  </Link>
                </Typography>
              </Grid>
              <Grid
                size={{ md: 6, lg: 4 }}
                sx={{
                  background: colors.white,
                  minHeight: 336,
                  borderRadius: "18px",
                  boxShadow: "0px 7px 41.5px -11px #00000040",
                  padding: "50px 14px 50px 50px",
                  position: "relative",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <div
                  style={{
                    width: 10,
                    height: 136,
                    background: colors.primary,
                    position: "absolute",
                    top: "50%",
                    left: 0,
                    borderRadius: 2,
                    transform: "translateY(-50%)",
                  }}
                />
                <Box sx={{ width: 40, height: 40 }}>
                  <img
                    src={require("../assets/image/mail.webp")}
                    alt={"Logo"}
                    loading="lazy"
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "contain",
                    }}
                  />
                </Box>
                <Box sx={{ flex: "1" }}>
                  <Typography
                    mt={"18px"}
                    sx={{
                      fontWeight: "bold",
                      fontSize: { xs: 19, md: 25 },
                      color: colors.gray,
                    }}
                  >
                    Mensajería digital
                  </Typography>

                  <Typography
                    sx={{
                      fontSize: { xs: 16, md: 18 },
                      color: colors.gray,
                      marginTop: "6px",
                    }}
                  >
                    Entregas electrónicas, SMS, email marketing y mas publicidad
                    en medios digitales
                  </Typography>
                </Box>

                <Typography
                  sx={{
                    fontSize: { xs: 16, md: 18 },
                    fontWeight: "bold",
                    color: colors.primary,
                    marginTop: "6px",
                  }}
                >
                  <Link
                    to="/mensajeria-digital"
                    style={{ textDecoration: "none", color: "inherit" }}
                  >
                    {` Ver más >`}
                  </Link>
                </Typography>
              </Grid>
              <Grid
                size={{ md: 6 }}
                sx={{
                  background: colors.white,
                  minHeight: 336,
                  borderRadius: "18px",
                  boxShadow: "0px 7px 41.5px -11px #00000040",
                  padding: "50px 14px 50px 50px",
                  position: "relative",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <div
                  style={{
                    width: 10,
                    height: 136,
                    background: colors.primary,
                    position: "absolute",
                    top: "50%",
                    left: 0,
                    borderRadius: 2,
                    transform: "translateY(-50%)",
                  }}
                />
                <Box sx={{ width: 40, height: 40 }}>
                  <img
                    src={require("../assets/image/page.webp")}
                    alt={"Logo"}
                    loading="lazy"
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "contain",
                    }}
                  />
                </Box>
                <Box sx={{ flex: "1" }}>
                  <Typography
                    mt={"18px"}
                    sx={{
                      fontWeight: "bold",
                      fontSize: { xs: 19, md: 25 },
                      color: colors.gray,
                    }}
                  >
                    Contrato de domicilio
                  </Typography>

                  <Typography
                    sx={{
                      fontSize: { xs: 16, md: 18 },
                      color: colors.gray,
                      marginTop: "6px",
                    }}
                  >
                    Obtén un domiciliario certificado y exclusivo para tu
                    empresa, esto te permitirá contar con personal de apoyo
                    siempre que lo requieras.
                  </Typography>
                </Box>

                <Typography
                  sx={{
                    fontSize: { xs: 16, md: 18 },
                    fontWeight: "bold",
                    color: colors.primary,
                    marginTop: "6px",
                  }}
                >
                  <Link
                    to="/contrato-de-domicilio"
                    style={{ textDecoration: "none", color: "inherit" }}
                  >
                    {` Ver más >`}
                  </Link>
                </Typography>
              </Grid>
              <Grid
                size={{ md: 12, lg: 6 }}
                sx={{
                  background: colors.white,
                  minHeight: 336,
                  borderRadius: "18px",
                  boxShadow: "0px 7px 41.5px -11px #00000040",
                  padding: "50px 14px 50px 50px",
                  position: "relative",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <div
                  style={{
                    width: 10,
                    height: 136,
                    background: colors.primary,
                    position: "absolute",
                    top: "50%",
                    left: 0,
                    transform: "translateY(-50%)",
                  }}
                />
                <Box sx={{ width: 40, height: 40 }}>
                  <img
                    src={require("../assets/image/airplane.webp")}
                    alt={"Logo"}
                    loading="lazy"
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "contain",
                    }}
                  />
                </Box>
                <Box sx={{ flex: "1" }}>
                  <Typography
                    mt={"18px"}
                    sx={{
                      fontWeight: "bold",
                      fontSize: { xs: 19, md: 25 },
                      color: colors.gray,
                    }}
                  >
                    Estrategias de Marketing
                  </Typography>

                  <Typography
                    sx={{
                      fontSize: { xs: 16, md: 18 },
                      color: colors.gray,
                      marginTop: "6px",
                    }}
                  >
                    ¿Quieres impulsar tus ventas? conoce medios digitales que te
                    abrirán más canales de venta y genera más posicionamiento en
                    redes.
                  </Typography>
                </Box>

                <Typography
                  sx={{
                    fontSize: { xs: 16, md: 18 },
                    fontWeight: "bold",
                    color: colors.primary,
                    marginTop: "6px",
                  }}
                >
                  <Link
                    to="/estrategias-de-marketing"
                    style={{ textDecoration: "none", color: "inherit" }}
                  >
                    {` Ver más >`}
                  </Link>
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Box>
        {/* Mobiles */}
        <Box
          sx={{
            background: colors.primary,
            height: "auto",
            marginY: { xs: "100px", lg: "170px" },
          }}
        >
          <Box
            justifyContent={"center"}
            alignItems={"center"}
            display={"flex"}
            flexDirection={"column"}
            sx={{
              flexGrow: 1,
              width: { xs: "90%", xl: "80%" },
              margin: "0 auto",
              height: "100%",
            }}
          >
            <Grid container spacing={3} width={"100%"} height={"100%"}>
              <Grid
                size={{ md: 12, lg: 4 }}
                // justifyContent={"space-around"}
                display={"flex"}
                flexDirection={"column"}
                sx={{
                  marginY: { lg: "6.5%" },
                  marginBottom: { xs: "80px", lg: "" },
                }}
                order={{ xs: 2, lg: 1 }}
              >
                <Box display={"flex"} flexDirection={"column"}>
                  <div
                    style={{
                      width: 136,
                      height: 10,
                      background: colors.white,
                    }}
                  />
                  <Typography
                    sx={{
                      fontWeight: "600",
                      fontSize: { xs: 19, md: 25 },
                      color: colors.white,
                      marginTop: "14px",
                    }}
                  >
                    LLEVA TODO EL CONTROL DE TU TRABAJO A TRAVÉS DE UN{" "}
                    <b>APLICATIVO MÓVIL</b>
                  </Typography>
                </Box>

                <Typography
                  sx={{
                    fontWeight: "bold",
                    fontSize: { xs: 19, md: 25 },
                    color: colors.white,
                    marginTop: "50px",
                  }}
                >
                  REGÍSTRATE CON NOSOTROS
                </Typography>
              </Grid>
              <Grid
                size={{ md: 12, lg: 8 }}
                order={{ xs: 1, lg: 2 }}
                sx={{
                  position: "relative",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Box
                  sx={{
                    width: { lg: "850px", xl: "100%" },
                    height: { lg: "800px", xl: "620px" },
                    minWidth: "455px",
                    minHeight: "400px",
                    marginTop: { xs: "0", lg: "-206px", xl: "-118px" },
                    left: 0,
                    position: { xs: "relative", lg: "absolute" },
                  }}
                >
                  <img
                    src={require("../assets/image/mobiles.webp")}
                    alt={"Logo"}
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "contain",
                    }}
                  />
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
