import React from "react";
import { Box, Typography, Button, ButtonProps } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { styled } from "@mui/material/styles";
import { colors, sizes } from "../../styles/theme.tsx";
import { Link } from "react-router-dom";

export default function Register({ setModuleText }) {
  const ColorButton = styled(Button)<ButtonProps>(({ theme }) => ({
    color: colors.white,
    backgroundColor: colors.primary,
    "&:hover": {
      backgroundColor: colors.white,
      border: `1px solid ${colors.primary}`,
      color: colors.primary,
    },
    borderRadius: 15,
    width: "100%",
    fontWeight: 700,
    margin: "0 auto",
    marginTop: 20,
    padding: "12px 0",
  }));

  return (
    <Box
      sx={{
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        overflowX: "hidden",
      }}
    >
      <Box
        sx={{
          flex: "1",
          flexGrow: 1,
          marginTop: "50px !important",
          width: { xs: "90%", md: "70%", lg: "60%", xl: "48%" },
          paddingTop: "60px",
          display: "flex",
          alignItems: "center",
          margin: "0 auto",
        }}
      >
        <Box
          display={"flex"}
          flexDirection={"column"}
          sx={{
            flexGrow: 1,
            margin: "0 auto",
          }}
        >
          <Box sx={{ position: "relative", width: "100%" }}>
            <div
              style={{
                width: 10,
                height: 89,
                background: colors.primary,
                position: "absolute",
                top: "45%",
                left: 0,
                borderRadius: 2,
                transform: "translateY(-50%)",
              }}
            />
            <Box pl="50px">
              <Typography
                sx={{
                  fontWeight: "bold",
                  fontSize: { xs: 32, md: 43 },
                  color: colors.primary,
                  textTransform: "uppercase",
                }}
                lineHeight={1}
              >
                REGÍSTRATE
              </Typography>

              <Typography
                sx={{
                  fontSize: { xs: 21.5, md: 24.5 },
                  color: colors.gray,
                  marginTop: "10px",
                  lineHeight: 1.3,
                }}
              >
                ¡Las mejores soluciones para ti!
              </Typography>
            </Box>
          </Box>
          <Box>
            <Grid
              sx={{
                background: colors.white,
                height: "auto",
                borderRadius: "18px",
                boxShadow: "0px 7px 41.5px -11px #00000040",
                padding: {
                  xs: "40px 20px 100px 20px",
                  sm: " 70px",
                },
                position: "relative",
                display: "flex",
                flexDirection: "column",
                width: "100%",
                mb: 10,
              }}
              mt={10}
            >
              <Box
                sx={{
                  width: { xs: 180, lg: 235 },
                  height: { xs: 180, lg: 235 },
                  margin: "0 auto",
                  marginTop: { xs: "-80px", sm: "-120px" },
                }}
              >
                <img
                  src={require("../../assets/image/user.webp")}
                  alt={"Logo"}
                  loading="lazy"
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "contain",
                  }}
                />
              </Box>

              <Box
                display={"flex"}
                flexDirection={"column"}
                alignItems={"center"}
                mt={6}
              >
                <Typography
                  sx={{
                    fontSize: { xs: 19, md: 21.8 },
                    color: colors.black,
                    marginTop: "10px",
                    lineHeight: 1.3,
                  }}
                >
                  Primero cúentanos
                </Typography>
                <Typography
                  sx={{
                    fontWeight: "bold",
                    fontSize: { xs: 29, md: 39 },
                    color: colors.primary,
                    textTransform: "uppercase",
                    textAlign: "center",
                  }}
                  lineHeight={1}
                >
                  ¿QUÉ TIPO DE USUARIO ERES?
                </Typography>

                <Grid
                  container
                  spacing={{ xs: 0, sm: 2 }}
                  width={{ sm: 355, md: 370, lg: 435 }}
                  marginTop={"30px"}
                >
                  <Grid size={{ xs: 12, sm: 6 }}>
                    <Link
                      to="/formulario-registro"
                      style={{ textDecoration: "none", color: "inherit" }}
                      onClick={() => setModuleText("1")}
                    >
                      <ColorButton
                        variant="contained"
                        sx={{ fontSize: { lg: 15, xl: 14 } }}
                      >
                        EMPRESARIO
                      </ColorButton>
                    </Link>
                  </Grid>
                  <Grid size={{ xs: 12, sm: 6 }}>
                    <Link
                      to="/formulario-registro"
                      style={{ textDecoration: "none", color: "inherit" }}
                      onClick={() => setModuleText("2")}
                    >
                      <ColorButton
                        variant="contained"
                        sx={{ fontSize: { lg: 15, xl: 14 } }}
                      >
                        MICROEMPRESARIO
                      </ColorButton>
                    </Link>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
